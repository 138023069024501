<template>
  <div class="card border-bot">
    <div class="card-body">
      <slot></slot>
      <h5 class="card-title">{{ itemName }}</h5>
      <p class="card-text">{{ itemDescription }}</p>
      <b-button @click="handleSetItem" variant="primary" class="mt-5">{{
        btnText
      }}</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'card-mrp-lp',
  props: {
    category: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    itemDescription: {
      type: String,
      default: '',
    },
    btnUrl: {
      type: String,
      default: '/',
    },
    btnText: {
      type: String,
      default: 'View More',
    },
    externalUrl: {
      type: String,
      default: '',
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSetItem() {
      this.$emit('set_item', {
        itemId: this.itemId,
        category: this.category,
        externalUrl: this.externalUrl
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.card {
  justify-content: center;
  .card-body {
    text-align: center;
    .icon {
      margin: 0 auto;
    }
    .card-title {
      margin-bottom: 17px;
      margin-top: 15px;
      color: $primary-color;
      font-family: $DIN;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      text-align: center;
    }
    .card-text {
    }
  }
  .border-bot {
    border-bottom: 8px solid $primary-color;
    border-left: none !important;
    border-right: none !important;
    margin-right: none !important;
  }
}
</style>
